<template>
  <div :key="userId">
    <v-toolbar flat>
      <v-toolbar-title>User</v-toolbar-title>
      <v-divider class="mx-2" inset vertical></v-divider>
      <v-btn
        justify="space-around"
        color="primary"
        dark
        @click="slotDialog = !slotDialog"
        class="mb-2 mr-2 ma-2"
        >Slot Gift</v-btn
      >
      <v-btn
        justify="space-around"
        color="red"
        dark
        @click="chatDialog = !chatDialog"
        class="mb-2 mr-2 ma-2"
        >Chat Ban</v-btn
      >

      <kick-user-card-modal v-if="user" :userId="user.id" />
    </v-toolbar>
    <v-row>
      <v-col cols="3">
        <v-card color="#fa000" v-if="user" max-width="400" dark class="ma-3">
          <div>
            <v-card-title
              class="headline  font-weight-bold"
              style="display:inline-block;"
              >User Detail</v-card-title
            >
            <span>
              <v-icon class=" ml-12 mb-2" @click="updateUserDetail()"
                >edit</v-icon
              >
            </span>
            <v-divider></v-divider>
          </div>
          <v-card-text>
            <div>
              <b class=" white--text  "> Status: </b>
              <span>{{ user.status | toUserStatus }}</span>
            </div>
            <div>
              <b class=" white--text  "> Chip: </b>
              <span>{{ user.chipAmount | formatMoney }}</span>
            </div>
            <div>
              <b class=" white--text  "> Gem: </b>
              <span>{{ user.gemsAmount | formatMoney }}</span>
            </div>
            <div>
              <b class=" white--text  "> Level: </b>
              <span>{{ user.levelInfo.level }}</span>
            </div>
            <div>
              <b class=" white--text  "> Location: </b>
              <span
                >{{ user.locationInfo.country_long }} /
                {{ user.locationInfo.city }}</span
              >
            </div>

            <div>
              <b class=" white--text  "> BannedReason: </b>
              <span>{{ user.bannedReason | toBannedReason }}</span>
            </div>

            <div v-for="(item, index) in user" :key="index">
              <div v-if="nonField.indexOf(index) > -1"></div>
              <div v-else-if="index == 'flag'">
                <b class=" white--text  "> {{ index }}: </b>
                <v-img syle="  display: inline-block;   vertical-align: middle;"
                  :src="
                    `https://bundle.slotbase.net/assets/flags/${item.toLocaleLowerCase()}.png`
                  "
                  max-height="32"
                  max-width="32"
                  min-height="32"
                  min-width="32"
                />
              </div>
              <div v-else-if="typeof item != 'object'">
                <b class=" white--text  "> {{ index }}: </b> {{ item }}
              </div>

              <div v-else>
                <div>
                  <b class=" white--text  "> {{ index }}: </b>
                </div>
                <div
                  v-for="(item2, index2) in item"
                  :key="index2"
                  style="padding-left:10px"
                >
                  <b class=" white--text  "> {{ index2 }}: </b> {{ item2 }}
                </div>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="9">
        <v-row>
          <v-col cols="3">

            <v-card color="#fa000" v-if="user" max-width="400" dark>
              <v-card-title
                class="headline  font-weight-bold"
                style="display:block;"
                >Current Rtp</v-card-title
              >
              <v-divider></v-divider>
              <v-card-text>
                <div v-for="(item, index) in {totalBetOnMode:user.totalBetOnMode,totalWonOnMode:user.totalWonOnMode}" :key="index">
                  <div v-if="typeof item != 'object'">
                    <b class=" white--text  "> {{ index }}: </b> {{ item |formatMoney}}
                  </div>
                </div>
                <b class=" white--text"> User Rtp: </b> %{{Math.round(user.totalWonOnMode/user.totalBetOnMode*100)}}
              </v-card-text>
            </v-card>

            <v-card color="#fa000" v-if="user" max-width="400" dark>
              <v-card-title
                class="headline  font-weight-bold"
                style="display:block;"
                >Level Info</v-card-title
              >
              <v-divider></v-divider>
              <v-card-text>
                <div v-for="(item, index) in user.levelInfo" :key="index">
                  <div v-if="typeof item != 'object'">
                    <b class=" white--text  "> {{ index }}: </b> {{ item }}
                  </div>
                </div>
              </v-card-text>
            </v-card>
             <v-card color="#fa000" v-if="user" max-width="400" dark>
              <v-card-title
                class="headline  font-weight-bold"
                style="display:block;"
                >Location Info</v-card-title
              >
              <v-divider></v-divider>
              <v-card-text>
                <div v-for="(item, index) in user.locationInfo" :key="index">
                  <div v-if="typeof item != 'object'">
                    <b class=" white--text  "> {{ index }}: </b> {{ item }}
                  </div>
                </div>
              </v-card-text>
            </v-card>
                      <v-card color="#fa000" v-if="user" max-width="400" dark>
              <v-card-title
                class="headline  font-weight-bold"
                style="display:block;"
                >Player Online</v-card-title
              >
              <v-divider></v-divider>
              <v-card-text>
                <div v-for="(item, index) in user.playerOnline" :key="index">
                  <div v-if="typeof item != 'object'">
                    <b class=" white--text  "> {{ index }}: </b> {{ index==="lastOnline"?unixTimestampToFormattedDate(item):item }}
                  </div>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
 
          <v-col cols="3">
            <v-card color="#fa000" v-if="user" max-width="400" dark>
              <v-card-title
                class="headline  font-weight-bold"
                style="display:block;"
                >Leagues</v-card-title
              >
              <v-divider></v-divider>
              <v-card-text>
                <div v-for="(item, index) in user.league" :key="index">
                  <div v-if="typeof item != 'object'">
                    <b class=" white--text  "> {{ index }}: </b> {{ item }}
                  </div>
                </div>
              </v-card-text>
            </v-card>
            <v-card color="#fa000" v-if="user" max-width="400" dark>
              <v-card-title
                class="headline  font-weight-bold"
                style="display:block;"
                >Device Info</v-card-title
              >
              <v-divider></v-divider>
              <v-card-text>
                <div v-for="(item, index) in user.deviceInfo" :key="index">
                  <div v-if="typeof item != 'object'">
                    <b class=" white--text  "> {{ index }}: </b> {{ item }}
                  </div>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="3">
            <v-card color="#fa000" v-if="user" max-width="400" dark>
              <v-card-title
                class="headline  font-weight-bold"
                style="display:block;"
                >Bonus</v-card-title
              >
              <v-divider></v-divider>
              <v-divider></v-divider>
              <v-card-text>
                <div v-for="(item, index) in user.bonusInfo" :key="index">
                  <div v-if="typeof item != 'object'">
                    <b class=" white--text  "> {{ index }}: </b> {{ item }}
                  </div>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="3">
            <v-card color="#fa000" v-if="user" max-width="400" dark>
              <v-card-title
                class="headline  font-weight-bold"
                style="display:block;"
                >Platform Info</v-card-title
              >
              <v-divider></v-divider>
              <v-card-text>
                <div v-for="(item, index) in user.platformInfo" :key="index">
                  <div v-if="typeof item != 'object'">
                    <b class=" white--text  "> {{ index }}: </b> {{ item }}
                  </div>
                </div>
              </v-card-text>
            </v-card>
            <v-card color="#fa000" v-if="user" max-width="400" dark>
              <v-card-title
                class="headline  font-weight-bold"
                style="display:block;"
                >Player Settings</v-card-title
              >
              <v-divider></v-divider>
              <v-card-text>
                <div v-for="(item, index) in user.playerSettings" :key="index">
                  <div v-if="typeof item != 'object'">
                    <b class=" white--text  "> {{ index }}: </b> {{ item }}
                  </div>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
    
  
        </v-row>
      </v-col>
    </v-row>
    <SlotGiftCard
      v-if="user"
      :clicked="slotDialog"
      :userId="user.id"
      :userName="user.userName"
      :lang="user.playerSettings.l.split('-')[0]"
      :notificationToken="user.deviceInfo.notificationToken"
    />
    <ChatBannedModalCard :clicked="chatDialog" :userId="user.id" />
    <v-row justify="center">
      <v-dialog v-model="updateUserDetailDialog" max-width="1600px">
        <v-card>
          <v-card-title>
            <span class="headline">User Detail Update</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    v-model="updateModel.chipAmount"
                    :placeholder="user.chipAmount"
                    label="Chip Amount"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    v-model="updateModel.gemsAmount"
                    :placeholder="user.gemsAmount"
                    label="Gems Amount"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    v-model="updateModel.level"
                    :placeholder="user.levelInfo.level"
                    label="Level"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              text
              @click="updateUserDetailDialog = false"
              >Close</v-btn
            >
            <v-btn color="blue darken-1" text @click="handleUserDetailUpdate()"
              >Save</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import SlotGiftCard from "@/views/players/components/SlotGiftCard";
import ChatBannedModalCard from "@/views/userReports/components/ChatBannedModalCard";
import KickUserCardModal from "./KickUserCardModal.vue";


export default {
  name: "UserProfileCard",
  components: { SlotGiftCard, ChatBannedModalCard, KickUserCardModal },
  data() {
    return {
      slotDialog: false,
      chatDialog: false,
      updateUserDetailDialog: false,
      updateModel: {
        userId: this.user.id,
        chipAmount: this.user.chipAmount,
        gemsAmount: this.user.gemsAmount,
        level: this.user.levelInfo.level,
      },
      nonField: [
        "league",
        "bonusInfo",
        "handInfo",
        "foldRatios",
        "levelInfo",
        "deviceInfo",
        "platformInfo",
        "playerSettings",
        "locationInfo",
        "playerOnline",
      ],
    };
  },
  props: {
    user: {
      type: Object,
      description: "user",
    },
  },
  methods: {
    async adminKick() {
      await this.$store.dispatch("users/kickUser", this.user.id);
      this.$store.dispatch("setAlert");
    },

    updateUserDetail() {
      this.updateUserDetailDialog = true;
    },
    async handleUserDetailUpdate() {
      await this.$store.dispatch("users/updateUserDetail", this.updateModel);
    },
    unixTimestampToFormattedDate(unixTimestamp){
    if(unixTimestamp===null)
        return null;
    var date = new Date(unixTimestamp * 1000); // Unix zaman damgasını milisaniyeye çevir
    var options = { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' };
    var formattedDate = new Intl.DateTimeFormat('tr-TR', options).format(date);
    return formattedDate;
    }
  },
};
</script>
