<template>
  <div>
    <v-toolbar flat>
      <v-toolbar-title>Chip Transfer</v-toolbar-title>
      <v-divider class="mx-2" inset vertical></v-divider>
      <v-text-field
        v-model="search"
        label="Search"
        single-line
        v-on:keyup.enter="initialize"
        hide-details
      ></v-text-field>
      <v-divider class="mx-2" inset vertical></v-divider>
      <v-combobox
        v-model="searchTypeSelect"
        :items="searchTypes"
        :loading="$store.state.progress"
        item-value="id"
        item-text="value"
        @change="initialize"
        hide-details
        label="Search Type"
      ></v-combobox>
      <v-divider class="mx-2" inset vertical></v-divider>
      <v-text-field
        v-model="minChip"
        label="MinChip"
        v-on:keyup.enter="initialize"
        hide-details
      ></v-text-field>
      <v-divider class="mx-2" inset vertical></v-divider>
      <v-text-field
        v-model="maxChip"
        label="MaxChip"
        v-on:keyup.enter="initialize"
        hide-details
      ></v-text-field>
      <v-divider class="mx-2" inset vertical></v-divider>
      <v-btn color="primary" dark @click="initialize" class="mb-2"
        >Search</v-btn
      >
    </v-toolbar>
    <v-data-table
      :headers="headers"
      v-if="chipTransfer"
      hide-default-footer
      :items="chipTransfer.data"
      :options.sync="options"
      :items-per-page="1000"
      class="elevation-1"
    >
      <template v-slot:item.cd="{ item }">
        <div>{{ new Date(item.cd * 1000) | formatDate }}</div>
      </template>
      <template v-slot:item.u="{ item }">
        <UserDetailCard
          :userId="item.ui"
          :pictureUrl="item.pu"
          :userName="item.u"
          :level="item.f"
          :flag="item.p"
        />
      </template>
      <template v-slot:item.fn="{ item }">
        <UserDetailCard
          :userId="item.fu"
          :pictureUrl="item.fp"
          :userName="item.fn"
          :level="item.fl"
          :flag="item.ff"
        />
      </template>
      <template v-slot:item.cd="{ item }">
        <div>{{ new Date(item.cd * 1000) | formatDate }}</div>
      </template>
      <template v-slot:item.c="{ item }">
        <div>{{ item.c | formatMoney }}</div>
      </template>
      <template v-slot:item.t="{ item }">
        <div>{{ item.t | formatMoney }}</div>
      </template>
      <template v-slot:item.fc="{ item }">
        <div>{{ item.fc | formatMoney }}</div>
      </template>
      <template v-slot:item.cm="{ item }">
        <div>{{ item.cm | formatMoney }}</div>
      </template>
    </v-data-table>
    <div class="text-center pt-2">
      <v-pagination
        v-model="currentPage"
        @input="pageClick"
        :length="chipTransfer.totalPage"
        :total-visible="20"
      ></v-pagination>
    </div>
  </div>
</template>
<script>
export default {
  name: "ChipDetailCard",
  components: {
    UserDetailCard: () => import("@/components/Users/UserDetailCard.vue"),
  },
  props: {
    search: {
      type: String,
      description: "search",
    },
  },
  data() {
    return {
      dialog: false,
      currentPage: 1,
      minChip: "",
      maxChip: "",
      chipTransfer: {},
      options: {},
      searchTypeSelect: { id: -1, value: "All" },
      searchTypes: [
        { id: 0, value: "All" },
        { id: 1, value: "From User" },
        { id: 2, value: "To User" },
      ],
      headers: [
        { text: "From User", value: "fn" },
        { text: "From Chip", value: "fc" },
        { text: "User", value: "u" },
        { text: "User Chip", value: "t" },
        { text: "Sended Chip", value: "c" },
        { text: "Commission", value: "cm" },
        { text: "Ip", value: "ip" },
        { text: "Created Date", value: "cd" },
      ],
    };
  },
  watch: {
    options: {
      handler() {
        this.initialize();
      },
      deep: true,
    },
  },
  methods: {
    async initialize() {
      if (this.minChip == "") this.minChip = "0";
      if (this.maxChip == "") this.maxChip = "0";
      this.chipTransfer = await this.$store.dispatch("chipTransfers/getAll", {
        page: this.currentPage - 1,
        search: this.search,
        minChip: this.minChip.split(",").join(""),
        maxChip: this.maxChip.split(",").join(""),
        searchTypeSelect: this.searchTypeSelect,
        options: this.options,
      });
    },
    pageClick(a) {
      this.currentPage = a;
      this.initialize();
    },
  },
  async mounted() {},
};
</script>
