<template>
  <div>
    <v-toolbar flat>
      <v-toolbar-title>Tax Pay</v-toolbar-title>
      <v-divider class="mx-2" inset vertical></v-divider>
      <v-text-field
        v-model="search"
        label="Search"
        single-line
        v-on:keyup.enter="initialize"
        hide-details
      ></v-text-field>
      <v-divider class="mx-2" inset vertical></v-divider>
      <v-combobox
        v-model="searchTypeSelect"
        :items="searchTypes"
        :loading="$store.state.progress"
        item-value="id"
        item-text="value"
        @change="initialize"
        hide-details
        label="Search Type"
      ></v-combobox>
      <v-divider class="mx-2" inset vertical></v-divider>

      <v-btn color="primary" dark @click="initialize" class="mb-2"
        >Search</v-btn
      >
    </v-toolbar>
    <v-data-table
      :headers="headers"
      v-if="chipTransfer"
      hide-default-footer
      :options.sync="options"
      :items="chipTransfer.data"
      :items-per-page="1000"
      class="elevation-1"
    >
      <template v-slot:item.userId="{ item }">
        <UserDetailCard
          :userId="item.userId"
          :pictureUrl="item.pictureUrl"
          :userName="item.userName"
          :level="item.level"
          :flag="item.flag"
        />
      </template>
      <template v-slot:item.createdTime="{ item }">
        <div>{{ new Date(item.createdTime * 1000) | formatDate }}</div>
      </template>
      <template v-slot:item.decrementGold="{ item }">
        <div>{{ item.decrementGold | formatMoney }}</div>
      </template>
      <template v-slot:item.collected="{ item }">
        <div>{{ item.collected | formatMoney }}</div>
      </template>
      <template v-slot:item.goldAmount="{ item }">
        <div>{{ item.goldAmount | formatMoney }}</div>
      </template>
      <template v-slot:item.tax="{ item }">
        <div>{{ item.tax | formatMoney }}</div>
      </template>
    </v-data-table>
    <div class="text-center pt-2">
      <v-pagination
        v-model="currentPage"
        @input="pageClick"
        :total-visible="20"
        :length="chipTransfer.totalPage"
      ></v-pagination>
    </div>
  </div>
</template>
<script>
export default {
  name: "TaxPayListCard",
  components: {
    UserDetailCard: () => import("@/components/Users/UserDetailCard.vue"),
  },
  props: {
    search: {
      type: String,
      description: "search",
    },
  },
  data() {
    return {
      dialog: false,
      currentPage: 1,
      chipTransfer: {},
      options: {},

      searchTypeSelect: { id: null, value: "All" },
      searchTypes: [
        { id: null, value: "All" },
        { id: -1, value: "Any" },
        { id: 0, value: "Holdem" },
        { id: 1, value: "Royal" },
        { id: 2, value: "Slot" },
        { id: 4, value: "Turbo" },
        { id: 5, value: "Jump" },
        { id: 6, value: "Arena" },
        { id: 7, value: "Spin" },
        { id: 8, value: "Black" },
        { id: 9, value: "Red" },
        { id: 10, value: "SlotRoyal" },
      ],
      headers: [
        { text: "User", value: "userId" },
        { text: "Collected", value: "collected" },
        { text: "decrementGold", value: "decrementGold" },
        { text: "goldAmount", value: "goldAmount" },
        { text: "roomId", value: "roomId" },
        { text: "roomName", value: "roomName" },
        { text: "smallBlind", value: "smallBlind" },
        { text: "tax", value: "tax" },
        { text: "server", value: "server" },
        { text: "Created Date", value: "createdTime" },
      ],
    };
  },
  watch: {
    options: {
      handler() {
        this.initialize();
      },
      deep: true,
    },
  },
  methods: {
    async initialize() {
      this.chipTransfer = await this.$store.dispatch("taxpays/getAll", {
        page: this.currentPage - 1,
        search: this.search,
        searchTypeSelect: this.searchTypeSelect,
        options: this.options,
      });
    },
    pageClick(a) {
      this.currentPage = a;
      this.initialize();
    },
  },
  async mounted() {},
};
</script>
